.header-main-section{
   font-weight: 400;
  font-size: 16px;
  line-height: 1.7;
  color: #777;
  width: 100%;
}

.header-textbox{
    position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header-background{
    height: 100vh;
    background-image: 
    /* linear-gradient(
        to right bottom,
        rgba(15, 240, 179, 0.5),
        rgba(9, 184, 195, 0.5)
      ), */
      
      url(../../photos/city.jpeg);
      filter: brightness(0.8);
     
    background-size: cover;
    background-position: center;
    position: relative;
}

.heading-primary{
  text-transform: uppercase;
  color: black;
}

.heading-primary-main {
    display: block;
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 1rem;
    text-align: center;
    color: black;
    padding: 2%;
    background-color: rgba(240, 255, 255, 0.607);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;


  }

  .heading-primary img{
    margin-top: -30%;
    width: 110%;
  }

  @media ( max-width:900px) {
    .heading-primary img{
      width: 150%;
      height: 110px;
      margin-left: -50px;
    }

    .heading-primary-main {
      font-size: 1rem;
      letter-spacing: 0.1rem;
    }
    
  }