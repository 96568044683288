body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;

}

.content{
    background-color: #fdfaf5;
}

.home-about-section{
    margin:5%;
    padding:3%;
}

.home-about-section h2{
    padding-top: 2%;
    text-align: center;
}

.about-content{
    display: flex;
    flex-wrap:wrap;
    margin: 3%;
}

.about-content-left{
    flex: 1;
    margin-top: auto;
    margin-bottom: auto;
    
}

.desc{
    text-indent: 1.2rem;
    text-align: left;
    font-size: 1.2rem;
}

.about-content-right{
flex: 1;
}

.about-content-right img{
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.about-content-right p{
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
}

.contact-section{
    margin:5%;
    padding:2%;
}

.contact-section h3{
    margin-top: 10px;
    text-align: center;
    text-decoration: underline;
}

.contact-form{
    display: flex;
    flex-wrap: wrap;
    height: 100vh;
}

.contact-form-left{
    flex: 1;
    margin-top: auto;
    margin-bottom: auto;
    padding:2%;
}

.contact-form-right{
    flex: 1;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        padding: 10px;
        margin-top: auto;
        margin-bottom: auto;
    background-color: #fff;
}

.map-section{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10%;
    height: auto;
}

.map-section h2{
    margin-top: 5%;
    text-align: center;
}

.map-content{
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}

.footer{
    margin-top: 5%;
    background-color: grey;
    color: #fff;
    padding: 2%;
    bottom:0;
    width: 100%;
    position: absolute;
}

@media (max-width: 900px) { 

    .about-content{
        flex-direction: column-reverse;
        margin-bottom: 10%;
        height: auto;
        margin-bottom: auto;
    }

    .about-content-right{
        width: 100%;
        margin: auto;
    }

    .about-content-left{
        width:100%;
        margin-bottom: auto;
        height: auto;
    }

    .contact-form{
        flex-direction: column;
        flex-wrap: nowrap;
        height: auto;
        margin-bottom: auto;
    }
    
}


@media (min-width: 1400px) {
    .home-about-section  h2{
        font-size: 3rem;
    }
    .about-content-right img{
        height: 30rem;
    }
    .about-content-right p{
        font-size: 3rem;
    }
    .desc{
        font-size: 2rem;
    }
}