.service-content-section{
    margin:5%;
    padding:2%;
    height: auto;
}

.service-heading h2{
    text-align: left;
    margin-bottom: 2%;
    
}
#service-section-one{
    margin-top: 3%;
}

.service-card-content{
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    padding:1%;
}

.service-card-group{
    width: calc(100% * (1/2) - 4%);
    margin-right:auto;
    margin-left:auto;
    margin-bottom: 4%;
    text-align: center;
}

.onsite-card-group{
    width: calc(100% * (1/2) - 5%);
    height: 50%;
    margin-left: auto ;
    margin-right: auto;
    text-align: center;

}

.service-img-card-content{
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
}
.service-img-card-group{
    width: calc(100% * (1/4));
    margin-right:auto;
    margin-left:auto;    
    padding-left: 1px;

}

.service-header h2{
    text-align: center;
}

.onsite-img-card-group{
    width: calc(100% * (1/2));
    height: 50%;
    margin-left: auto ;
    margin-right: auto;
    padding-left: 1px;

}

 @media (max-width: 900px){
    .service-card-content{
        flex-direction: column-reverse;
    }

    .service-heading h2{
        text-align: center;
    }


    .service-card-group{
        width: 100%;
    }

    .onsite-card-group{
        width: 100%;
    }
    
} 