.contact-form-template{
    padding: 5%;
}

.contact-form-template h4{
    text-align: center;
}

.contact-form-template .btn{
    margin-left: auto;
    margin-right: auto;
}