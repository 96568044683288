.service-card {
    display: flex; /* Use flexbox for better alignment */
    flex-direction: column; /* Arrange elements vertically */
    align-items: center; /* Center elements horizontally */
    max-width: 800px; /* Limit maximum width of the card */
    margin: 0 auto; /* Center the card horizontally */
    padding: 20px; /* Add some padding for spacing */
    background-color: #ffffff; /* Set background color */
    border-radius: 10px; /* Add rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

.service-card img {
    width: 100%; /* Ensure the image fills its container */
    height: auto; /* Allow the image to scale proportionally */
    max-height: 800px; /* Set maximum height to avoid stretching */
    border-top-left-radius: 10px; /* Match border radius of card */
    border-top-right-radius: 10px; /* Match border radius of card */
    object-fit: cover; /* Ensure image covers the entire container */
    margin-bottom: 5px;
}

.service-card h3 {
    margin: 10px 0; /* Add margin for spacing */
    font-size: 1.2rem; /* Set font size */
    color: #333333; /* Set text color */
}

.service-card p {
    margin: 0; /* Remove default margin */
    font-size: 1rem; /* Set font size */
    color: #666666; /* Set text color */
    text-align: center; /* Center-align text */
}
